html.is-scrolling {
  #mainmenu-toolbar {
    height: 76px;
  }

  #mainmenu-toolbar-shadow {
    opacity: 1;
  }
}

html.theme-mode-dark {
  #mainmenu-toolbar {
    background-color: rgba(22, 28, 36, 0.8);
  }
}
